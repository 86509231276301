import { faArrowLeft, faX, faRotateRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Custom500 from '../../pages/500'
import { makeRequest } from '../../services/axios'
import Payment from '../Payment/Payment'
import ShoppingBagOverview from '../ShoppingBagOverview/ShoppingBagOverview'
import styles from './ShoppingBagModal.module.scss'

interface SizzlyCheckoutSession {
  message: {
    sessionId: string
    sessionData: string
    orderRef: string
  }
  success: boolean
}

const ShoppingBagModal = ({
  showShoppingBag,
  setShowShoppingBag,
}: {
  showShoppingBag: any
  setShowShoppingBag: any
}) => {
  const restaurantUid = localStorage.getItem('localRestaurantUid')
  const restaurantId = localStorage.getItem('localRestaurantId')
  const [checkoutData, setCheckoutData] = useState(null)
  const [checkoutStep, setCheckoutStep] = useState(0)
  const [orderId, setOrderId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [fetchError, setFetchError] = useState<string | null>(null)
  const [tipAmount, setTipAmount] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [GUID, setGUID] = useState(null)
  const [session, setSession] = useState<SizzlyCheckoutSession>()

  const { t } = useTranslation(['shopping'])

  useEffect(() => {
    const makeRequestCheckout = async () => {
      let checkout = await makeRequest('get', `/restaurants/nameUid/${restaurantUid}`)
      const kitchenCapacity = await makeRequest('get', `/restaurants/${restaurantId}/kitchen-capacity`)
      if (checkout.nameUid && kitchenCapacity.kitchenCapacity) {
        checkout = Object.assign(checkout, kitchenCapacity)
        setCheckoutData(checkout)
      } else {
        setFetchError(checkout.code ? checkout.code : kitchenCapacity.code)
      }
      setLoading(false)
    }
    if (!checkoutData && !fetchError) {
      makeRequestCheckout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchError])

  return (
    <>
      <Modal
        show={showShoppingBag}
        onHide={() => {
          setShowShoppingBag(false)
        }}
        scrollable={true}
      >
        <Modal.Header className={classnames('modal-header text-center text-white border-0 ', styles.header)}>
          <Modal.Title className="modal-title w-100 border-0">
            <div className="ms-3 w-100">
              <div className="w-100 bg-transparent border-0 row">
                <button
                  className={classnames('btn rounded-circle bg-white', styles.backButton)}
                  onClick={() => {
                    if (checkoutStep !== 2) {
                      setShowShoppingBag(false)
                    } else {
                      setCheckoutStep(1)
                    }
                  }}
                >
                  {checkoutStep !== 2 ? (
                    <FontAwesomeIcon icon={faX} size={'sm'} />
                  ) : (
                    <FontAwesomeIcon icon={faArrowLeft} size={'sm'} />
                  )}
                </button>
                <span className="w-75 text-center justify-content-center">
                  {checkoutStep === 0 ? t('shopping:bag') : t('shopping:paymentMethods')}
                </span>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={classnames('modal-body', styles.backgroundColor, styles.modalBody)}>
          {!checkoutData && !fetchError && (
            <div className="text-center">
              <Spinner animation="border" className={styles.sizzlyColor} />
            </div>
          )}
          {checkoutData && (checkoutStep === 0 || checkoutStep === 1) && (
            // @ts-expect-error
            <ShoppingBagOverview
              // @ts-expect-error
              restaurantId={restaurantId}
              // @ts-expect-error
              restaurantUid={restaurantUid}
              data={checkoutData}
              setShoppingBag={setShowShoppingBag}
              checkoutStep={checkoutStep}
              setCheckoutStep={setCheckoutStep}
              setOrderId={setOrderId}
              loading={loading}
              setLoading={setLoading}
              error={error}
              setError={setError}
              tipAmount={tipAmount}
              setTipAmount={setTipAmount}
              t={t}
              discount={discount}
              setDiscount={setDiscount}
              setGUID={setGUID}
              GUID={GUID}
              session={session}
              setSession={setSession}
            />
          )}
          {checkoutData && orderId && checkoutStep === 2 && (
            <Payment
              orderId={orderId}
              setCheckoutStep={setCheckoutStep}
              setLoading={setLoading}
              loading={loading}
              // @ts-expect-error
              cashPayment={checkoutData?.cashPayments}
              error={error}
              setError={setError}
              tipAmount={tipAmount}
              t={t}
              discount={discount}
              setDiscount={setDiscount}
              GUID={GUID}
              session={session}
            />
          )}
          {fetchError && (
            <div className="text-center">
              <button
                className={classnames('btn rounded-pill text-uppercase', styles.sizzlyPrimaryButton)}
                onClick={() => {
                  setCheckoutData(null)
                  setFetchError(null)
                }}
              >
                <FontAwesomeIcon icon={faRotateRight} size={'sm'} className="pe-2" />
                Neu laden
              </button>
              <Custom500 />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ShoppingBagModal
