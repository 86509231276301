import Image from 'next/future/image'
import styles from './LoadingAnimation.module.scss'
import classnames from 'classnames'

const LoadingAnimation = (props: any) => {
  return (
    <div className={props.loading || props.loading === null ? styles.loadingDiv : styles.displayNone}>
      <Image src={'/shfmLogo.png'} width={162.6} height={35.8} alt="sizzly Logo" className="mb-2" />
      <div className={styles.dotFlash} />
      {/*<div className="mt-3">{description}</div>*/}
    </div>
  )
}

export default LoadingAnimation
