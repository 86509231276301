import Image from 'next/image'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import styles from './Footer.module.scss'
import classnames from 'classnames'
import Script from 'next/script'

const Footer = () => {
  const { t } = useTranslation(['common'])
  return (
    <footer className={classnames(styles.bottomFooter, 'py-4 footer')}>
      <div className={styles.footerContainer}>
        <div className={classnames(styles.footerElement, 'pb-4')}>
          <div className={classnames(styles.footerHeading, 'h7')}>{t('common:contact')}</div>
          <div className="pb-2">
            <a>sizzly GmbH</a>
            <a>Böheimstraße 101B</a>
            <a>70199 Stuttgart</a>
          </div>

          <div className="pb-2">
            <a>{t('common:visitorAddress')}</a>
            <a>Schelmenwasenstraße 32</a>
            <a>70567 Stuttgart</a>
          </div>

          <div className={styles.footerBlock}>
            <a href="mailto:info@sizzly.de">info@sizzly.de</a>
            <a href="sizzly.de">sizzly.de</a>
          </div>
        </div>
        <div className={styles.footerElement}>
          <div className={classnames(styles.footerHeading, 'h7')}>{t('common:legal')}</div>
          <Link href="/legal/1">{t('common:imprint')}</Link>
          <Link href="/legal/2">{t('common:dataProtection')}</Link>
          <Link href="/legal/3">{t('common:withdrawalForm')}</Link>
          <Link href="/legal/4">{t('common:conditions')}</Link>
        </div>
      </div>
      <div className={classnames('justify-content-end', styles.bottomElement)}>
        <div className={styles.footerSocialMedia}>
          <a href="https://instagram.com/sizzly.de" rel="noreferrer noopener" target="_blank">
            <Image src="/instagram-square-brands.svg" alt="Instagram" height="30px" width="30px" />
          </a>
          <a href="https://de.linkedin.com/company/sizzly" rel="noreferrer noopener" target="_blank">
            <Image src="/linkedin-brands.svg" alt="LinkedIn" height="30px" width="30px" />
          </a>
          <a href="https://facebook.com/sizzly.de" rel="noreferrer noopener" target="_blank">
            <Image src="/square-facebook-brands.svg" alt="LinkedIn" height="30px" width="30px" />
          </a>
        </div>

        <div className={classnames(styles.footerLogoContainer, 'pt-2')}>
          <span className={styles.footerLogo}>
            <Image src="/shfmLogo.png" alt="Instagram" height="35.8px" width="162.6px" />
          </span>
          <span className={styles.footerCopyright}>Copyright 2023 sizzly</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
