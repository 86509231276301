import {
  faCircleMinus,
  faCirclePlus,
  faCoins,
  faPercent,
  faTrash,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { Accordion, FloatingLabel, Form, Spinner } from 'react-bootstrap'
import { makeRequest } from '../../services/axios'
import { calcLocalItemsAmount } from '../../services/calcLocalItemsAmount'
import { OpeningHours } from '../../services/openingHours'
import { orderRequest } from '../../services/orderRequest'
import { pricePerItem } from '../../services/pricePerItem'
import { Article, Day, Restaurant, SelectedOption } from '../../types'
import { useRouter } from 'next/router'
import styles from './ShoppingBagOverview.module.scss'

declare var window: any

const baseCardPaymentMethod = {
  type: 'CARD',
  parameters: {
    allowedCardNetworks: ['VISA', 'MASTERCARD'],
    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
  },
}
const googlePayBaseConfiguration = {
  apiVersion: 2,
  apiVersionMinor: 0,
  allowedPaymentMethods: [baseCardPaymentMethod],
}

const ShoppingBagOverview = ({
  data,
  setShoppingBag,
  checkoutStep,
  setCheckoutStep,
  setOrderId,
  loading,
  setLoading,
  error,
  setError,
  tipAmount,
  setTipAmount,
  t,
  restaurantId,
  discount,
  setDiscount,
  setGUID,
  GUID,
  restaurantUid,
  session,
  setSession,
}: {
  data: Restaurant
  setShoppingBag: any
  checkoutStep: any
  setCheckoutStep: any
  setOrderId: any
  loading: boolean
  setLoading: any
  error: string | null
  setError: any
  tipAmount: number | null
  setTipAmount: any
  t: any
  restaurantId: number
  discount: number
  setDiscount: any
  setGUID: any
  GUID: any
  restaurantUid: string
  session: any
  setSession: any
}) => {
  const [orderType, setOrderType] = useState(localStorage.getItem('orderType'))
  const [cartItems, setCartItems] = useState(
    localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart') as string) : null
  )
  const [updater, setUpdater] = useState(0)
  const [tipType, setTipType] = useState('money')
  const [tipCount, setTipCount] = useState(0)
  const [userName, setUserName] = useState<null | string>(localStorage.getItem('userName'))
  const [userMail, setUserMail] = useState<null | string>(localStorage.getItem('userInformation'))
  const [userCountryCode, setUserCountryCode] = useState<null | number | string>(
    localStorage.getItem('countryCode') ? localStorage.getItem('countryCode') : 49
  )
  const [userPhone, setUserPhone] = useState<null | number | string>(localStorage.getItem('userPhone'))
  const [preDeletion, setPreDeletion] = useState(false)
  const [errorCode, setErrorCode] = useState<null | string>(null)
  const [takeAwayTime, setTakeAwayTime] = useState<string | null>(null)
  const [tableNumber, setTableNumber] = useState(
    localStorage.getItem('tableNumber') ? localStorage.getItem('tableNumber') : null
  )
  const [overallQuantity, setOverallQuantity] = useState(0)
  const [comment, setComment] = useState<null | string>(null)
  const [showComment, setShowComment] = useState(false)
  const [kitchenClosed, setKitchenClosed] = useState(true)
  const [restaurantClosed, setRestaurantClosed] = useState(false)
  const [consent, setConsent] = useState(false)
  const applePayRef = useRef<any>(null)
  const [gPayBtn, setGPayBtn] = useState<any>(null)
  const [pickupSlotsAvailable, setPickupSlotsAvailable] = useState(false)

  const { locale } = useRouter()
  const { googlePayClient } = window

  useEffect(() => {
    const localPrice = JSON.parse(localStorage.getItem('localPrice') as string)
    const localTakeAwayModifier = JSON.parse(localStorage.getItem('localTakeAwayModifier') as string)
    if (tipType === 'money') {
      setTipAmount(tipCount)
    }
    if (tipType === 'percent') {
      if (orderType === 'takeAway') {
        setTipAmount((localPrice + (localTakeAwayModifier ? localTakeAwayModifier : 0)) * (tipCount / 100))
      } else {
        setTipAmount(localPrice * (tipCount / 100))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipType, tipCount])

  useEffect(() => {
    if (data) {
      const today = new Date()
      for (const [key, value] of Object.entries(data.shifts)) {
        if (key.toUpperCase() === today.toLocaleDateString('en-EN', { weekday: 'long' }).toUpperCase()) {
          for (const time of value as Day[]) {
            if (
              time.startTimeKitchen < today.toLocaleTimeString('de-DE') &&
              today.toLocaleTimeString('de-DE') < time.endTimeKitchen &&
              time.startTime < today.toLocaleTimeString('de-DE') &&
              today.toLocaleTimeString('de-DE') < time.endTime
            ) {
              setKitchenClosed(false)
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, cartItems])

  useEffect(() => {
    setOverallQuantity(
      cartItems.reduce((partSum: number, item: Article) => partSum + (item.quantity ? item.quantity : 1), 0)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updater, cartItems])

  useEffect(() => {
    if (orderType === 'takeAway' && cartItems.some((item: Article) => item.availableForTakeAway === false)) {
      setPreDeletion(true)
      setError(t('shopping:orderTypeMismatch'))
    } else {
      setPreDeletion(false)
      setError(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType, cartItems])

  useEffect(() => {
    const discountCheck = async () => {
      await applyDiscount()
    }
    discountCheck()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!orderType) {
      setError(t('shopping:selectOrderType'))
      setErrorCode('orderTypes')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const openingHours = OpeningHours(data.shifts, data.restaurantScheduledClosings)
    if (!openingHours.isOpen && orderType !== 'takeAway') {
      setError(t('shopping:restaurantClosed'))
      setRestaurantClosed(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType])

  useEffect(() => {
    if (data.emergencyShutdown) {
      setError(t('shopping:emergencyShutdown'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (window.ApplePaySession) {
      if (!ApplePaySession.canMakePayments() || !applePayRef.current) {
        console.log('nulled')
        return
      }
      const createSession = async () => {
        const request: any = {
          countryCode: 'DE',
          currencyCode: 'EUR',
          merchantCapabilities: ['supports3DS'],
          supportedNetworks: ['visa', 'masterCard', 'amex'],
          total: {
            label: 'sizzly',
            type: 'final',
            amount: totalPrice().toFixed(2),
          },
          requiredShippingContactFields: ['name', 'phone', 'email'],
        }

        const session = new ApplePaySession(3, request)

        let orderResponse: any

        session.onvalidatemerchant = async (event) => {
          await onPayClick(window.location.hostname)
            .then((response: any) => {
              orderResponse = response
            })
            .catch((e) => {
              console.log(e)
              session.abort()
            })
          if (orderResponse && orderResponse.applePaySession) {
            const buff = new Buffer(orderResponse.applePaySession, 'base64')
            let applePaySession = buff.toString('ascii')
            applePaySession = JSON.parse(applePaySession)
            try {
              session.completeMerchantValidation(applePaySession as any)
            } catch (e) {
              console.log(e)
            }
          } else {
            session.abort()
          }
        }

        session.onpaymentauthorized = async (event) => {
          // Define ApplePayPaymentAuthorizationResult
          const token = JSON.stringify(event.payment.token.paymentData)
          const data = {
            type: 'applepay',
            token,
            orderId: orderResponse.id,
            returnUrl: window.location.origin,
            contact: event.payment.shippingContact,
          }
          const applePayment = await makeRequest('post', '/adyen-payment/expressCheckout', data)
          const result = {
            status:
              applePayment.resultCode === 'Authorised'
                ? ApplePaySession.STATUS_SUCCESS
                : ApplePaySession.STATUS_FAILURE,
          }
          try {
            session.completePayment(result)
            if (applePayment.resultCode === 'Authorised') {
              window.location.href = `/checkout/${restaurantUid}/${orderResponse.guid}/`
            }
          } catch (e) {
            console.log(e)
          }
        }

        session.oncancel = (event) => {
          // Payment cancelled by WebKit
        }

        session.begin()
      }
      applePayRef.current.addEventListener('click', createSession)

      return () => {
        if (applePayRef.current) {
          applePayRef.current.removeEventListener('click', createSession)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applePayRef, cartItems, takeAwayTime, tableNumber, tipCount, consent, orderType, discount, tipAmount])

  useEffect(() => {
    const createAndAddButton = () => {
      if (googlePayClient) {
        const googlePayButton = googlePayClient.createButton({
          buttonColor: 'default',
          buttonType: 'plain',
          onClick: processPayment,
        })
        setGPayBtn(googlePayButton)
      }
    }
    googlePayClient
      .isReadyToPay(googlePayBaseConfiguration)
      .then((res: any) => {
        if (res.result) {
          createAndAddButton()
        } else {
          console.error('Unable to pay using Google Pay')
        }
      })
      .catch(function (err: any) {
        console.error('Error determining readiness to use Google Pay: ', err)
      })
  }, [])

  const processPayment = async () => {
    if (error) return

    const googleObject: any = data.expressMethods?.find(
      (x: any) => x.type === 'paywithgoogle' || x.type === 'googlepay'
    )

    const transactionInfo = {
      totalPriceStatus: 'FINAL',
      totalPrice: totalPrice().toFixed(2),
      currencyCode: 'EUR',
    }

    const tokenizationSpecification = {
      type: 'PAYMENT_GATEWAY',
      parameters: {
        gateway: 'adyen',
        gatewayMerchantId: googleObject.configuration?.gatewayMerchantId,
      },
    }

    const cardPaymentMethod = {
      type: 'CARD',
      tokenizationSpecification: tokenizationSpecification,
      parameters: {
        allowedCardNetworks: ['VISA', 'MASTERCARD'],
        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
        billingAddressRequired: true,
        billingAddressParameters: {
          format: 'MIN',
          phoneNumberRequired: true,
        },
      },
    }

    const merchantInfo = {
      merchantId: googleObject.configuration?.merchantId,
      merchantName: 'sizzly',
    }

    const paymentDataRequest = {
      ...googlePayBaseConfiguration,
      ...{
        allowedPaymentMethods: [cardPaymentMethod],
        transactionInfo,
        merchantInfo,
        emailRequired: true,
      },
    }
    googlePayClient
      .loadPaymentData(paymentDataRequest)
      .then((paymentData: any) => {
        onPayClick(null, 'paywithgoogle', paymentData)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const applyDiscount = async () => {
    const body = {
      restaurantId: restaurantId,
      value: userMail ? userMail.split('@').pop() : null,
      field: 'email',
    }

    const discountRequest = await makeRequest('post', '/restaurants/applyDiscount', body)
    if (discountRequest.discount) {
      setDiscount(discountRequest.discount)
    }
  }

  const itemUpdate = (index: number, type: string, itemPrice: any) => {
    let itemsCopy = cartItems
    const localPrice = JSON.parse(localStorage.getItem('localPrice') as string)
    const localTakeAwayModifier = JSON.parse(localStorage.getItem('localTakeAwayModifier') as string)
    if (type === 'trash') {
      localStorage.setItem('localPrice', JSON.stringify(localPrice - cartItems[index].quantity * itemPrice.price))
      localStorage.setItem(
        'localTakeAwayModifier',
        JSON.stringify(localTakeAwayModifier - cartItems[index].quantity * itemPrice.modifierTakeAway)
      )
      itemsCopy.splice(index, 1)
      if (itemsCopy.length === 0) {
        setShoppingBag(null)
        localStorage.removeItem('cart')
        localStorage.removeItem('localPrice')
        localStorage.removeItem('localTakeAwayModifier')
        localStorage.removeItem('localRestaurantId')
        localStorage.removeItem('localRestaurantUid')
      } else {
        localStorage.setItem('cart', JSON.stringify(itemsCopy))
        setCartItems(itemsCopy)
      }
      setUpdater(updater + 1)
    } else {
      itemsCopy[index].quantity = itemsCopy[index].quantity + (type === 'plus' ? 1 : -1)
      setCartItems(itemsCopy)
      setUpdater(updater + 1)
      localStorage.setItem('cart', JSON.stringify(itemsCopy))
      localStorage.setItem(
        'localPrice',
        JSON.stringify(localPrice + (type === 'plus' ? itemPrice.price : -itemPrice.price))
      )
      localStorage.setItem(
        'localTakeAwayModifier',
        JSON.stringify(
          localTakeAwayModifier + (type === 'plus' ? itemPrice.modifierTakeAway : -itemPrice.modifierTakeAway)
        )
      )
    }
  }

  const orderTypes = () => {
    const { restaurantTakeAway, restaurantInHouse, selfService } = data
    let orderTypesRestaurant = []
    if (restaurantTakeAway) {
      orderTypesRestaurant.push({ title: 'Takeaway', name: 'takeAway' })
    }
    if (restaurantInHouse) {
      orderTypesRestaurant.push({ title: 'Inhouse', name: 'inHouse' })
    }
    if (selfService) {
      orderTypesRestaurant.push({ title: 'Selfservice', name: 'selfService' })
    }
    if (orderTypesRestaurant.length === 1 && orderType !== orderTypesRestaurant[0].name) {
      setOrderType(orderTypesRestaurant[0].name)
    }
    return (
      <div className="text-center">
        {orderTypesRestaurant.map((name) => {
          return (
            <button
              key={name.name}
              className={classNames(
                'btn',
                errorCode === 'orderTypes' && 'border-danger',
                orderTypesRestaurant.length > 1 ? styles.orderType : styles.oneOrderType,
                orderType === name.name ? styles.orderTypeSelected : undefined
              )}
              onClick={() => {
                setOrderType(name.name)
                localStorage.setItem('orderType', name.name)
                if (errorCode === 'orderTypes') {
                  setErrorCode(null)
                }
              }}
            >
              {name.title}
            </button>
          )
        })}
      </div>
    )
  }

  const renderCartItems = () => {
    return (
      <Accordion className={classNames('accordion mt-3', styles.maxHeightAccordion)}>
        {cartItems.map((item: Article, index: number) => {
          const itemPrice = pricePerItem(item)
          return (
            <Accordion.Item eventKey={JSON.stringify(index)} className={classNames('mb-1 rounded')} key={index}>
              <Accordion.Button
                className={classNames(
                  'accordion-button',
                  'shoppingBag',
                  preDeletion && item.availableForTakeAway === false ? 'preDeletion' : undefined
                )}
              >
                <div className="container">
                  <div className="row">
                    <div className={classNames(styles.articleName, 'col-8')}>
                      {item.quantity}x {item.name}
                    </div>
                    <div className={classNames('col d-flex align-self-center justify-content-end me-auto me-3')}>
                      {(item.quantity * (itemPrice.price + (orderType === 'takeAway' ? itemPrice.modifierTakeAway : 0)))
                        .toFixed(2)
                        .replace('.', ',')}{' '}
                      €
                    </div>
                  </div>
                </div>
              </Accordion.Button>
              {item.orderModule && (
                <Accordion.Body className="container">
                  <div className={classNames('py-1 row', styles.expandedAccordionDivider)}>
                    <div className="col">{t('shopping:pricePerUnit')}:</div>
                  </div>
                  <div className={classNames('row align-items-center', styles.expandedAccordionDivider)}>
                    <div className={classNames(styles.articleName, 'col-8 py-1')}>1x {item.name}</div>
                    <div className={classNames('col text-end')}>
                      {(
                        item.price +
                        (item.amountPriceModifierTakeAway && item.modifierTypeTakeAway && orderType === 'takeAway'
                          ? item.modifierTypeTakeAway === 'surplus'
                            ? item.amountPriceModifierTakeAway
                            : -item.amountPriceModifierTakeAway
                          : 0)
                      )
                        .toFixed(2)
                        .replace('.', ',')}{' '}
                      €
                    </div>
                  </div>
                  {item.orderModule?.map((mod: SelectedOption) => {
                    return (
                      <div
                        className={classNames('row align-items-center py-1', styles.expandedAccordionDivider)}
                        key={mod.moduleName}
                      >
                        <div className="col-8">
                          {mod.quantity}x {mod.optionName}
                        </div>
                        <div className={classNames('col text-end')}>
                          {(
                            mod.quantity *
                            ((mod.modifierType && mod.priceModifier
                              ? mod.modifierType === 'surplus'
                                ? mod.priceModifier
                                : -mod.priceModifier
                              : 0) +
                              (mod.priceModifierTakeAway && mod.modifierTypeTakeAway && orderType === 'takeAway'
                                ? mod.modifierTypeTakeAway === 'surplus'
                                  ? mod.priceModifierTakeAway
                                  : -mod.priceModifierTakeAway
                                : 0))
                          )
                            .toFixed(2)
                            .replace('.', ',')}{' '}
                          €
                        </div>
                      </div>
                    )
                  })}
                  <div className={classNames('mt-2 align-middle', styles.sizzlyColor)}>
                    <button
                      className={classNames('btn me-2', styles.sizzlyColorBtn)}
                      onClick={() => {
                        itemUpdate(index, 'trash', itemPrice)
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} size={'lg'} />
                    </button>
                    <button
                      className={classNames('btn', styles.sizzlyColorBtn)}
                      disabled={item.quantity <= 1}
                      onClick={() => itemUpdate(index, 'minus', itemPrice)}
                    >
                      <FontAwesomeIcon icon={faCircleMinus} size={'lg'} />
                    </button>
                    <span className="align-middle">{item.quantity}x</span>
                    <button
                      className={classNames('btn', styles.sizzlyColorBtn)}
                      disabled={
                        item.quantity >= 9 ||
                        (item.trackItemAmount &&
                          (item.availableItems ? item.availableItems : Infinity) -
                            item.quantity -
                            calcLocalItemsAmount(cartItems, item.id) <=
                            0)
                      }
                      onClick={() => itemUpdate(index, 'plus', itemPrice)}
                    >
                      <FontAwesomeIcon icon={faCirclePlus} size={'lg'} />
                    </button>
                  </div>
                </Accordion.Body>
              )}
            </Accordion.Item>
          )
        })}
      </Accordion>
    )
  }

  const tipElement = () => {
    return (
      <div className="bg-light mt-3 py-2 rounded d-inline-block w-100">
        <span className="ms-4 align-self-center">{t('shopping:tip')}</span>
        <button
          className={classNames(
            'btn mx-2 p-0 rounded-pill',
            styles.inputDecoration,
            styles.tipButton,
            tipType === 'money' ? styles.tipButtonSelected : styles.tipButtonUnselect
          )}
          onClick={() => {
            setTipType('money')
            setTipCount(1)
          }}
        >
          <FontAwesomeIcon icon={faCoins} size={'lg'} />
        </button>
        <button
          className={classNames(
            'btn p-0 rounded-pill',
            styles.inputDecoration,
            styles.tipButton,
            tipType === 'percent' ? styles.tipButtonSelected : styles.tipButtonUnselect
          )}
          onClick={() => {
            setTipType('percent')
            setTipCount(1)
          }}
        >
          <FontAwesomeIcon icon={faPercent} size={'lg'} />
        </button>
        <span className="float-end me-2">
          <button
            className={classNames('btn', styles.sizzlyColorBtn)}
            onClick={() => setTipCount(tipCount - 1)}
            disabled={tipCount <= 0}
          >
            <FontAwesomeIcon icon={faCircleMinus} size={'lg'} />
          </button>
          <span>
            {tipCount}
            {tipType === 'money' ? ' €' : ' %'}
          </span>
          <button className={classNames('btn', styles.sizzlyColorBtn)} onClick={() => setTipCount(tipCount + 1)}>
            <FontAwesomeIcon icon={faCirclePlus} size={'lg'} />
          </button>
        </span>
      </div>
    )
  }

  const contactData = () => {
    return (
      <div className="bg-light rounded mt-4 p-2">
        <div className="ms-3">{t('shopping:contact')}</div>
        <div className="container form-floating w-100 text-center">
          <FloatingLabel controlId="floatingInput" label="Name" className={'my-2 w-100 rounded'}>
            <Form.Control
              type="text"
              placeholder="Name"
              onChange={(e) => setUserName(e.target.value)}
              onBlur={() => {
                if (typeof userName !== 'object') {
                  localStorage.setItem('userName', userName)
                } else {
                  localStorage.removeItem('userName')
                }
                if (errorCode === 'userName') {
                  setErrorCode(null)
                  setError(null)
                }
              }}
              // @ts-expect-error
              value={userName}
              className={classNames(
                (errorCode === 'userName' ||
                  (userName && !userName.match(/^[a-zA-Z]+ [a-zA-Z]+/) && !userName.match(/^[a-zA-Z]+/))) &&
                  'is-invalid',
                (errorCode === 'userName' ||
                  (userName && !userName.match(/^[a-zA-Z]+ [a-zA-Z]+/) && !userName.match(/^[a-zA-Z]+/))) &&
                  styles.errorBorder,
                userName && (userName.match(/^[a-zA-Z]+ [a-zA-Z]+/) || userName.match(/^[a-zA-Z]+/)) && 'is-valid'
              )}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="E-Mail" className={'my-2 w-100 rounded'}>
            <Form.Control
              type="email"
              placeholder="E-Mail"
              onChange={(e) => setUserMail(e.target.value)}
              onFocus={() => setDiscount(0)}
              onBlur={async () => {
                await applyDiscount()
                if (typeof userMail !== 'object') {
                  localStorage.setItem('userInformation', userMail)
                } else {
                  localStorage.removeItem('userInformation')
                }
                if (errorCode === 'userMail') {
                  setErrorCode(null)
                  setError(null)
                }
              }}
              // @ts-expect-error
              value={userMail}
              className={classNames(
                (errorCode === 'userMail' || (userMail && !userMail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/))) &&
                  styles.errorBorder,
                (errorCode === 'userMail' || (userMail && !userMail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/))) &&
                  'is-invalid',
                userMail && userMail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) && 'is-valid'
              )}
            />
          </FloatingLabel>

          {discount > 0 && (
            <div className="alert alert-success p-0 " role="alert">
              {t('shopping:discountText')} {discount.toFixed(2).replace('.', ',')} €.
            </div>
          )}
          {orderType !== 'inHouse' && !!!data.omitPhoneNumber && (
            <div className="w-100 d-flex">
              <FloatingLabel
                className={classNames('bg-white d-inline-block', styles.countryCodeInput)}
                controlId="floatingSelectGrid"
                // @ts-expect-error
                value={userCountryCode}
                onChange={(e) => {
                  // @ts-expect-error
                  setUserCountryCode(e.target.value)
                  // @ts-expect-error
                  localStorage.setItem('countryCode', e.target.value)
                }}
                label={t('shopping:countryCode')}
              >
                <Form.Select>
                  <option value={49}>+49</option>
                  <option value={33}>+33</option>
                  <option value={1}>+1</option>
                  <option value={43}>+43</option>
                  <option value={32}>+32</option>
                  <option value={359}>+359</option>
                  <option value={385}>+385</option>
                  <option value={420}>+420</option>
                  <option value={45}>+45</option>
                  <option value={39}>+39</option>
                  <option value={31}>+31</option>
                  <option value={48}>+48</option>
                  <option value={351}>+351</option>
                  <option value={40}>+40</option>
                  <option value={34}>+34</option>
                  <option value={41}>+41</option>
                  <option value={44}>+44</option>
                </Form.Select>
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInputGrid"
                label={t('shopping:phone')}
                className={classNames('rounded d-inline-block ms-2', styles.phoneInput)}
              >
                <Form.Control
                  type="text"
                  placeholder={t('shopping:phone')}
                  onChange={(e) => {
                    const result = e.target.value.replace(/\D/g, '')
                    setUserPhone(result)
                  }}
                  onBlur={() => {
                    if (typeof userPhone !== 'object') {
                      // @ts-expect-error
                      localStorage.setItem('userPhone', userPhone)
                    } else {
                      localStorage.removeItem('userPhone')
                    }
                    if (errorCode === 'userPhone') {
                      setErrorCode(null)
                      setError(null)
                    }
                  }}
                  // @ts-expect-error
                  value={userPhone}
                  className={classNames(
                    errorCode === 'userPhone' && orderType === 'takeAway' && 'is-invalid',
                    errorCode === 'userPhone' && orderType === 'takeAway' && styles.errorBorder,
                    userPhone && !(errorCode === 'userPhone') && 'is-valid'
                  )}
                />
              </FloatingLabel>
            </div>
          )}
        </div>
      </div>
    )
  }

  const totalPrice = () => {
    const localPrice = JSON.parse(localStorage.getItem('localPrice') as string)
    const localTakeAwayModifier = JSON.parse(localStorage.getItem('localTakeAwayModifier') as string)
    return orderType !== 'takeAway'
      ? localPrice + -discount + (tipAmount ? tipAmount : 0)
      : localPrice + -discount + (localTakeAwayModifier ? localTakeAwayModifier : 0) + (tipAmount ? tipAmount : 0)
  }

  const selectOrderOptionAndPrice = () => {
    const today = new Date()
    return (
      <div>
        {orderType === 'takeAway' && (
          <div className={classNames(' p-3 align-self-center rounded bg-light mt-3 py-2')}>
            <FloatingLabel
              onChange={(e) => {
                // @ts-expect-error
                setTakeAwayTime(e.target.value)
              }}
              onBlur={() => {
                setErrorCode(null)
                setError(null)
              }}
              label={t('shopping:pickupTime')}
              controlId="floatingInput"
            >
              <Form.Select className={classNames(errorCode === 'pickUpTime' && styles.errorBorder)}>
                <option hidden>{pickupSlotsAvailable ? '-' : t('shopping:noSlots')}</option>
                {Object.entries(data.kitchenCapacity).map(([key, value]) => {
                  const kitchenCapacityDate = new Date(key)
                  let modifierKitchenCapacityDate
                  const minPickupDate = Date.now() + data.orderRules?.noticePickupTime * 60 * 1000
                  if (data.orderRules?.noticePickupTime) {
                    modifierKitchenCapacityDate = new Date(
                      kitchenCapacityDate.setTime(kitchenCapacityDate.getTime() + data.orderRules?.noticePickupTime)
                    )
                  }
                  if (
                    today.toDateString() ===
                      (modifierKitchenCapacityDate
                        ? modifierKitchenCapacityDate
                        : kitchenCapacityDate
                      ).toDateString() &&
                    minPickupDate < kitchenCapacityDate.getTime() &&
                    overallQuantity <= (value as number) &&
                    today <= kitchenCapacityDate
                  ) {
                    if (!pickupSlotsAvailable && (value as number) > 0) {
                      setPickupSlotsAvailable(true)
                    }
                    return (
                      <option value={key} key={key}>
                        {kitchenCapacityDate.getHours()}:
                        {kitchenCapacityDate.getMinutes() < 10
                          ? `${kitchenCapacityDate.getMinutes()}0`
                          : kitchenCapacityDate.getMinutes()}{' '}
                        {t('shopping:time')}
                      </option>
                    )
                  }
                })}
              </Form.Select>
            </FloatingLabel>
          </div>
        )}
        {orderType === 'inHouse' && (
          <div className={classNames(styles.sizzlyColor, ' bg-light w-100 rounded mt-3 py-2 px-3')}>
            <FloatingLabel label={t('shopping:table')}>
              <Form.Control
                onChange={(e) => {
                  const result = e.target.value.replace(/\D/g, '')
                  setTableNumber(result)
                  localStorage.setItem('tableNumber', result)
                }}
                onBlur={() => {
                  if (errorCode === 'tableNumber') {
                    setErrorCode(null)
                    setError(null)
                  }
                }}
                // @ts-expect-error

                value={tableNumber}
                placeholder={t('shopping:table')}
                className={classNames(
                  errorCode === 'tableNumber' && orderType === 'inHouse' && 'is-invalid',
                  errorCode === 'tableNumber' && orderType === 'inHouse' && styles.errorBorder,
                  tableNumber && !(errorCode === 'tableNumber') && 'is-valid'
                )}
              ></Form.Control>
            </FloatingLabel>
          </div>
        )}
        <div className={classNames('h4 rounded w-100 bg-light py-3 ps-4 mt-3', styles.sizzlyColor)}>
          <span className="h6 ">{t('shopping:price')}:</span> {totalPrice().toFixed(2).replace('.', ',')} €
        </div>
      </div>
    )
  }

  const onDeleteItem = (item: Article) => {
    if (item.availableForTakeAway === false) {
      let localPrice = JSON.parse(localStorage.getItem('localPrice') as string)
      let localTakeAwayModifier = JSON.parse(localStorage.getItem('localTakeAwayModifier') as string)
      const priceOfItemToRemove = pricePerItem(item)
      localPrice = localPrice - priceOfItemToRemove.price
      localTakeAwayModifier = localTakeAwayModifier - priceOfItemToRemove.modifierTakeAway
      localStorage.setItem('localTakeAwayModifier', JSON.stringify(localTakeAwayModifier))
      localStorage.setItem('localPrice', JSON.stringify(localPrice))
    }
    return item.availableForTakeAway === true
  }

  const onPayClick = async (appleUrl?: string | null, type?: string | null, googleData?: any) => {
    let response: any
    const regexMail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (preDeletion === true) {
      let localCartItems = cartItems
      localCartItems = localCartItems.filter((item: Article) => {
        return onDeleteItem(item)
      })
      setError(null)
      setPreDeletion(false)
      setCartItems(localCartItems)
      setUpdater(updater + 1)
      if (localCartItems < 1) {
        setShoppingBag(false)
        localStorage.removeItem('cart')
        localStorage.removeItem('localPrice')
        localStorage.removeItem('localTakeAwayModifier')
      }
      localStorage.setItem('cart', JSON.stringify(localCartItems))
      return
    } else if (
      kitchenClosed &&
      !restaurantClosed &&
      cartItems.some((x: any) => x.type === 'dish' && orderType === 'inHouse')
    ) {
      setError(t('shopping:kitchenClosedError'))
      return
    } else if (
      kitchenClosed &&
      restaurantClosed &&
      cartItems.some((x: any) => x.type === 'dish' && orderType === 'inHouse')
    ) {
      setError(t('shopping:restaurantClosed'))
      return
    } else if ((!userName || userName.trim().length === 0) && !appleUrl && type !== 'paywithgoogle') {
      setErrorCode('userName')
      setError(t('shopping:provideName'))
      return
    } else if (
      !userPhone &&
      (orderType === 'selfService' || orderType === 'takeAway') &&
      !appleUrl &&
      type !== 'paywithgoogle' &&
      !!!data.omitPhoneNumber
    ) {
      setErrorCode('userPhone')
      setError(t('shopping:providePhone'))
      return
    } else if (!takeAwayTime && orderType === 'takeAway') {
      setErrorCode('pickUpTime')
      setError(t('shopping:providePickupTime'))
      return
    } else if (
      (!userMail || userMail.trim().length === 0 || !regexMail.test(userMail)) &&
      !appleUrl &&
      type !== 'paywithgoogle'
    ) {
      setErrorCode('userMail')
      setError(t('shopping:provideEmail'))
      return
    } else if (orderType === 'inHouse' && !tableNumber) {
      setErrorCode('tableNumber')
      setError(t('shopping:provideTable'))
      return
    } else if (!consent) {
      setErrorCode('consent')
      setError(t('shopping:consent'))
      return
    } else {
      let gres: any = await new Promise(async (res, rej) => {
        await window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY, {
              action: 'submit',
            })
            .then((token: string) => {
              res(token)
            })
            .catch(() => rej())
        })
      })
      if (gres) {
        setError(null)
        setErrorCode(null)
        setLoading(true)
        const { id } = data
        const orderResponse = await orderRequest(
          id,
          tipAmount,
          comment,
          'creditCard',
          googleData ? googleData.paymentMethodData.info.billingAddress.name : userName,
          googleData ? googleData.email : userMail,
          googleData ? '' : userCountryCode,
          googleData ? googleData.paymentMethodData.info.billingAddress.phoneNumber.replace(/[^0-9]/g, '') : userPhone,
          orderType,
          tableNumber,
          takeAwayTime,
          gres,
          cartItems,
          overallQuantity,
          appleUrl
        )
        setLoading(false)
        if (orderResponse.id) {
          setGUID(orderResponse.guid)
          setOrderId(orderResponse.id)
          response = orderResponse
          if (!appleUrl && !type) {
            setLoading(true)
            const adyenSessionConfig = {
              channel: 'Web',
              returnUrl: `${process.env.NEXT_PUBLIC_OWN_URL}/${
                locale === 'de' ? '' : `${locale}/`
              }/checkout/${restaurantUid}/${orderResponse.guid}/`,
              orderId: orderResponse.id,
            }
            const adyenSessionResponse = await makeRequest('post', '/adyen-payment/session', adyenSessionConfig)
            if (adyenSessionResponse.success === true) {
              setSession(adyenSessionResponse)
              setCheckoutStep(2)
            } else {
              setError(t('shopping:paymentNotAvailable'))
            }
            setLoading(false)
            response = { ...orderResponse, ...adyenSessionResponse }
          }
          if (googleData) {
            setLoading(true)
            console.log(process.env.NEXT_PUBLIC_OWN_URL)
            const paymentData = {
              type: 'googlepay',
              token: googleData.paymentMethodData.tokenizationData.token,
              orderId: orderResponse.id,
              returnUrl: `${process.env.NEXT_PUBLIC_OWN_URL}/checkout/${restaurantUid}/${orderResponse.guid}/`,
            }
            const googlePayment = await makeRequest('post', '/adyen-payment/expressCheckout', paymentData)
            if (googlePayment.resultCode === 'Authorised') {
              window.location.href = `/checkout/${restaurantUid}/${orderResponse.guid}/`
            } else {
              setError(t('shopping:gPayError'))
            }
            setLoading(false)
          }
        } else {
          console.log(orderResponse.response?.data?.data?.code)
          switch (orderResponse.response?.data?.data?.code) {
            case '400.001':
              setError(t('shopping:closedError'))
              break
            case '400.002':
              setError(t('shopping:pickupError'))
              break
            case '400.003':
              setError(t('shopping:pickupError'))
              break
            case '400.004':
              setError(t('shopping:orderTypeMismatch'))
              break
            case '400.005':
              setError(t('shopping:generalError'))
              break
            case '400.006':
              setError('Der Warenkorb ist leer.')
              break
            case '400.007':
              setError(t('shopping:pickupError'))
              break
            case '400.028' || '503':
              setError(t('shopping:restaurantAvailabilityError'))
              break
            case '400.034':
              setError(t('shopping:gresError'))
              break
            case '400.035':
              setError(t('shopping:takeAwayNotAvailable'))
              break
            case '400.036':
              setError(t('shopping:inHouseNotAvailable'))
              break
            case '400.037':
              setError(t('shopping:selfServiceNotAvailable'))
              break
            case '400.038':
              setError(
                t('shopping:orderVolumeNotReachedStart') +
                  orderType +
                  t('shopping:orderVolumeNotReachedEnd') +
                  data.orderRules?.minVolumeTakeAway.toFixed(2).replace('.', ',') +
                  ' €'
              )
              break
            case '400.039':
              setError(
                t('shopping:orderVolumeNotReachedStart') +
                  orderType +
                  t('shopping:orderVolumeNotReachedEnd') +
                  data.orderRules?.minVolumeInHouse.toFixed(2).replace('.', ',') +
                  ' €'
              )
              break
            case '400.053':
              setError(t('shopping:provideEmail'))
              break
            case '429':
              setError(t('shopping:backendOverflowError'))
              break
            default:
              setError(t('shopping:generalError'))
          }
          return
        }
        return response
      }
    }
  }

  if (data) {
    return (
      <div className="">
        {orderTypes()}
        {renderCartItems()}
        {!data.disableComments && (
          <div className="mt-2">
            <button
              className={classNames('btn p-0', styles.sizzlyColor, styles.orderCommentButton)}
              onClick={() => setShowComment(!showComment)}
            >
              {t('shopping:addComment')}
            </button>
          </div>
        )}
        {showComment && (
          <FloatingLabel label={t('shopping:comment')} controlId="floatingInputGrid">
            <Form.Control onChange={(e) => setComment(e.target.value)} maxLength={59} />
          </FloatingLabel>
        )}
        {!!!data.disableTip && tipElement()}
        {selectOrderOptionAndPrice()}
        <label className={classNames('d-flex align-items-center mt-3 ps-2')}>
          <input
            type="checkbox"
            onClick={() => {
              setConsent(!consent)
            }}
            checked={consent}
            className={styles.consetCheckbox}
          />
          <span className="ps-4 text-dark">
            {t('shopping:acceptTerms')}{' '}
            <a href="/legal/4" className={classNames(styles.sizzlyColor, styles.orderCommentButton)}>
              {t('shopping:terms')}
            </a>{' '}
            {t('shopping:and')}{' '}
            <a href="/legal/2" className={classNames(styles.sizzlyColor, styles.orderCommentButton)}>
              {t('shopping:conditions')}
            </a>
            .
          </span>
        </label>
        {error && (
          <div className="alert alert-danger my-3" role="alert">
            {error}
          </div>
        )}
        {((data.expressMethods?.find((x: any) => x.type === 'applepay') &&
          window.ApplePaySession &&
          ApplePaySession.canMakePayments()) ||
          (gPayBtn && data.expressMethods?.find((x: any) => x.type === 'paywithgoogle' || x.type === 'googlepay'))) &&
          !data.emergencyShutdown && (
            <>
              <div className={classNames('mt-4 px-1 py-3 text-center', styles.expressDiv)}>
                <div className={classNames('position-absolute', styles.expressText)}>
                  <a className="px-1">EXPRESS-CHECKOUT</a>
                </div>
                <span className="h-100 pe-1">
                  {/*@ts-expect-error*/}
                  <apple-pay-button buttonstyle="black" type="plain" locale="de-DE" ref={applePayRef}>
                    {/*@ts-expect-error*/}
                  </apple-pay-button>
                </span>

                <span
                  onClick={processPayment}
                  dangerouslySetInnerHTML={{ __html: gPayBtn && gPayBtn.innerHTML }}
                  className="h-100"
                />
              </div>
              <div className={classNames('mt-4', styles.divDivider)} />
              <div className={styles.differentiation}>
                <a className="px-1 text-uppercase">{t('shopping:or')}</a>
              </div>
            </>
          )}
        {(checkoutStep === 1 || (!window.ApplePaySession && !gPayBtn) || !data.expressMethods) && contactData()}
        <button
          className={classNames(
            'btn mt-4 w-100 text-center rounded-pill text-light text-uppercase',
            styles.buttonMargin,
            (checkoutStep === 1 || !data.expressMethods) && styles.sizzlyBackground,
            checkoutStep === 1 && styles.sizzlyColorBtn,
            checkoutStep === 0 && !!data.expressMethods && styles.sizzlyBtnBorderColored,
            preDeletion && 'bg-danger'
          )}
          onClick={async () => {
            if (checkoutStep == 0 && (window.ApplePaySession || gPayBtn) && !!data.expressMethods) {
              setCheckoutStep(1)
            } else {
              await onPayClick()
            }
          }}
          disabled={loading || !orderType || data.emergencyShutdown || (restaurantClosed && orderType !== 'takeAway')}
        >
          {preDeletion && t('shopping:deleteArticles')}
          {!preDeletion &&
            !loading &&
            ((!gPayBtn && !window.ApplePaySession) || !data.expressMethods || checkoutStep === 1) &&
            t('shopping:continue')}
          {!preDeletion &&
            !loading &&
            (gPayBtn || window.ApplePaySession) &&
            !!data.expressMethods &&
            checkoutStep === 0 && (
              <a>
                <FontAwesomeIcon icon={faChevronDown} size={'lg'} /> {t('shopping:furtherPaymentOptions')}
              </a>
            )}
          {loading && (
            <span>
              <Spinner animation="border" size={'sm'} color={'white'} /> {t('shopping:loading')}
            </span>
          )}
        </button>
      </div>
    )
  }
}

export default ShoppingBagOverview
